<template>
    <div>
        <h1>Join Poolinq</h1>
        <b-form @submit="register">
            <b-form-group>
                <b-form-input v-model="name" type="text" placeholder="Enter full name"></b-form-input>
                <b-form-input v-model="username" type="text" placeholder="Enter username"></b-form-input>
                <b-form-input v-model="email" type="email" placeholder="Enter email"></b-form-input>
                <b-form-input v-model="password" type="password" placeholder="Enter password"></b-form-input>
                <b-button type="submit" variant="primary">Register</b-button>
            </b-form-group>

            <p v-show="error" class="">{{ errorMsg }}</p>
        </b-form>
    </div>
</template>
<script>
    import DataService from '@/services/StrapiService'
    export default {
        name: 'Register',
        data() {
            return {
                name: '',
                email: '',
                password: '',
                username: '',
                error: false,
                errorMsg: `An Error occurred, please try again`,
                strapiUrl: DataService.getServerUrl()
            }
        },
        methods: {
            async register(e) {
                try {
                    e.preventDefault()
                        await this.axios.post(this.strapiUrl+`/auth/local/register`, {
                        name: this.name,
                        password: this.password,
                        email: this.email,
                        username: this.username
                    })
                    this.$router.push('login')
                } catch(e) {
                    this.error = true
                    this.email = ''
                } 
            }
        }
    }
</script>
<style scoped>
</style>